<template>
  <div class="userVideo">
    <PullRefresh
      :loading="loading"
      :refreshing="refreshing"
      :finished="finished"
      @onLoad="onLoad"
      @onRefresh="onRefresh"
      :isNoData="isNoData"
      :isHigehtMax="false"
      :error="error"
    >
      <VideoList
        :dataList="list"
        :page="pageNumber"
        :isTop="$route.query.uid && $route.path != '/userHomePage' ? false : true"
        fromPath="userHomePage"
      />
    </PullRefresh>
  </div>
</template>

<script>
import PullRefresh from '@/components/PullRefresh';
import VideoList from '@/components/VideoList';
import { queryCollection } from '@/api/video';
export default {
  name: 'userVideo',
  components: {
    PullRefresh,
    VideoList,
  },
  data() {
    return {
      list: [],
      pageNumber: 1, // 页码
      pageSize: 10, // 条数
      loading: true, // loading
      refreshing: false, // 刷新状态
      finished: false, // 下拉状态
      error: false, // 是否显示错误信息
      isNoData: false, // 暂无数据
    };
  },
  created() {
    // console.log(this.$route)
    this.getList('refresh');
  },
  methods: {
    // 查询列表
    async getList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        type: 'new', // hot-热度值排序 watch-最多播放 like-最多点赞(收藏) new-最新
        newsType: 'SP', // SP-视频 COVER-图集帖子 MOVIE-影视  FORWARD-转发帖子
        uid: this.$route.query.uid != this.$store.getters.userInfo.uid ? this.$route.query.uid : undefined,
      };
      let res = await this.$Api(queryCollection, req);
      this.loading = false;
      this.refreshing = false;
      if (res.code === 200) {
        let list = res.data.list || [];
        if (type === 'refresh') {
          this.list = list;
        } else {
          this.list = this.list.concat(list);
        }
        // this.isNoData = this.comList.length > 0 ? false : true;
        if (this.list.length <= 0) {
          this.isNoData = true;
        } else {
          this.list.length > 0 && this.$emit('showPress');
        }
        if (!res.data.hasNext) {
          this.finished = true;
        }
      } else {
        this.error = true;
      }
    },
    // 上拉加载
    onLoad() {
      this.loading = true;
      this.pageNumber++;
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = false;
      this.getList('refresh');
    },
    refreshList() {
      this.pageNumber = 1;
      // this.refreshing = true;
      // this.finished = false;
      this.loading = true;
      this.getList('refresh');
    },
  },
};
</script>

<style lang="scss" scoped>
.userVideo {
  height: 100%;
  /deep/ .van-pull-refresh {
    min-height: 400px;
  }
}
</style>
