<template>
  <div class="userHomePage" ref="userHomePage" @scroll="handleScroll">
    <div class="navBar" v-show="showNav">
      <div @click="$router.go(-1)">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
      </div>
      <div class="centerBox">
        <ImgDecypt class="navAvatar" :src="userInfo.portrait" round :key="userInfo.portrait" />
        <div :class="{ vipName: userInfo.isVip && userInfo.vipLevel }">{{ userInfo.name }}</div>
      </div>

      <van-popover v-model="showPop" theme="dark" trigger="click" placement="bottom-end" :overlay="true" :actions="actions" @select="selectPopover">
        <template #reference>
          <div class="rightBtn">
            <svg-icon class="horizontalMore" iconClass="horizontalMore"></svg-icon>
          </div>
        </template>
      </van-popover>
    </div>
    <div class="loading-box" v-if="isLoading">
      <Loading />
    </div>
    <div v-if="!isLoading">
      <div class="header">
        <ImgDecypt class="bgImg" :src="backgroundImg" />
        <div class="backBtn" @click="$router.go(-1)">
          <svg-icon iconClass="userBack"></svg-icon>
        </div>
        <div class="moreBox">
          <van-popover
            v-model="showPopover"
            theme="dark"
            trigger="click"
            placement="bottom-end"
            :overlay="true"
            :actions="actions"
            @select="selectPopover"
          >
            <template #reference>
              <div class="moreBtn">
                <svg-icon class="more" iconClass="userMore"></svg-icon>
              </div>
            </template>
          </van-popover>
        </div>
      </div>

      <div class="optBox">
        <div class="avatarImgBox" :class="{ isCertifica: userInfo.merchantUser == 1 }">
          <ImgDecypt class="avatarImg" :src="userInfo.portrait" round />
          <div class="certification" v-if="userInfo.superUser">
            <Vlogo :vid="userInfo.id" />
          </div>
          <svg-icon class="certificaBorder" iconClass="certificaBorder" v-if="userInfo.merchantUser == 1" />
        </div>
        <div class="smsButtom" v-if="$route.query.uid && $route.query.uid != myInfo.uid" @click="privateLetter">私信</div>
        <div :class="userInfo.isFollow ? 'followed' : 'follow'" v-if="$route.query.uid && $route.query.uid != myInfo.uid" @click="follow">
          {{ userInfo.isFollow ? '已关注' : '关注' }}
        </div>
      </div>
      <div class="userBox">
        <div class="userInfo">
          <div class="userName" :class="{ vipName: userInfo.isVip && userInfo.vipLevel }">{{ userInfo.name }}</div>
          <svg-icon class="userSexIcon" iconClass="man" v-if="userInfo.gender == 'man'"></svg-icon>
          <svg-icon class="userSexIcon" iconClass="woman" v-if="userInfo.gender == 'woman'"></svg-icon>
          <svg-icon class="userIcon" iconClass="crown" v-if="userInfo.isVip && userInfo.vipLevel > 0"></svg-icon>
          <div class="userIcon" v-for="icon in userIcons" :key="icon.number">
            <ImgDecypt :src="icon.imgUrl" v-if="icon.isExpire" />
          </div>
        </div>
        <div class="userId" @click="copyUrl(userInfo.uid)">ID: {{ userInfo.uid }}</div>
      </div>
      <div class="introduce">{{ userInfo.summary }}</div>

      <div class="userData">
        <div>
          {{ userInfo.like | watchCount }}
          <span>获赞</span>
        </div>
        <div @click="jumpFocusOn">
          {{ userInfo.follow | watchCount }}
          <span>关注</span>
        </div>
        <div @click="jumpFans">
          {{ userInfo.fans | watchCount }}
          <span>粉丝</span>
        </div>
      </div>

      <div class="rewardBox">
        <div class="reward">
          <div class="avatars" v-if="rewardList.length > 0">
            <ImgDecypt class="avatarItem" :src="item.uPortrait" round v-for="(item, index) in rewardList" :key="index" />
          </div>
          <div class="rewardNum" v-if="rewardList.length > 0">{{ userInfo.beRewardCount || 0 }}人打赏</div>
          <div v-if="rewardList.length <= 0" class="noReward">还没人打赏呢，快来成为NO.1吧~</div>
          <div class="rewardBtn" v-if="$route.query.uid && $route.query.uid != myInfo.uid" @click="showReward">打赏</div>
          <div style="width: 64px" v-else></div>
        </div>
      </div>

      <van-tabs
        v-model="activeName"
        sticky
        offset-top="1.402rem"
        background="rgb(255,255,255)"
        title-active-color="rgb(255,103,143)"
        title-inactive-color="rgb(51,51,51)"
        class="vant_tabs"
        animated
        :swipeable="false"
      >
        <van-tab title="抖音" :key="0">
          <div class="scrollList vList" v-if="activeName == 0">
            <UserVideo ref="video" />
          </div>
        </van-tab>
        <van-tab title="图文" :key="1">
          <div class="scrollList" v-if="activeName == 1">
            <UserPost ref="post" @followedAllSuccess="followedAllSuccess" />
          </div>
        </van-tab>
        <van-tab title="影视" :key="2" v-if="movieList.length > 0">
          <div class="scrollList vList" v-if="activeName == 2">
            <UserMovie ref="movie" @followedAllSuccess="followedAllSuccess" />
          </div>
        </van-tab>
        <!--      <van-tab title="转发" :key="2">-->
        <!--        <div class="scrollList" v-if="activeName == 2">-->
        <!--          <UserForward ref="forward" @followedAllSuccess="followedAllSuccess" />-->
        <!--        </div>-->
        <!--      </van-tab>-->
      </van-tabs>
    </div>

    <van-popup v-model="isReward" class="setAmoutBox" @close="close">
      <div class="popupsBox">
        <div class="popTitle">为喜欢的UP主加油</div>
        <div class="amountList">
          <div class="amountItem" :class="{ activeAmout: activeAmount == i }" v-for="i in amountList" :key="i" @click="selectAmout(i)">
            <svg-icon class="goldIcon" icon-class="gold" />
            {{ i }}
          </div>
          <div class="amountItem">
            <van-field type="number" v-model="inpAmount" placeholder="其他金额" @focus="inputAmount" />
          </div>
        </div>
        <div class="balance">钱包余额：{{ balance }}</div>
        <div class="popBtn" @click="setAmount">立即打赏</div>
      </div>
      <div class="closeBtn" @click="isReward = false">
        <svg-icon class="closeIcon" icon-class="y_close" />
      </div>
    </van-popup>

    <van-popup v-model="setTopData.popShow" position="bottom" class="setTopPop">
      <div class="line"></div>
      <div class="contentBox">
        <div class="contentBox_item" v-show="setTopData.type == 'SP'">
          <div class="leftBox">
            <svg-icon class="setTop" icon-class="promotiona" />
            是否推广视频
          </div>
          <div class="rightBox">
            <div class="setTopBtn" :class="{ activeBtn: setTopData.promoteStatus == 2 }" @click="unset(2)">否</div>
            <div class="setTopBtn" :class="{ activeBtn: setTopData.promoteStatus == 1 }" @click="goRouter">是</div>
          </div>
        </div>
        <div class="contentBox_item">
          <div class="leftBox">
            <svg-icon class="setTop" icon-class="setTop" />
            是否置顶视频
          </div>
          <div class="rightBox">
            <div class="setTopBtn" :class="{ activeBtn: setTopData.action == 2 }" @click="selectSetTop(2)">否</div>
            <div class="setTopBtn" :class="{ activeBtn: setTopData.action == 1 }" @click="selectSetTop(1)">是</div>
          </div>
        </div>
      </div>
    </van-popup>

    <div class="pressTip" @click="closePressTip" v-if="showPressTip && !$route.query.uid">
      <div class="pressBox">
        <div class="pressImg"></div>
        <div class="pressText">长按可推广置顶视频</div>
      </div>
    </div>
  </div>
</template>

<script>
import ImgDecypt from '@/components/ImgDecypt';
import Vlogo from '@/components/Vlogo';
import UserVideo from './UserVideo';
import UserPost from './UserPost';
import UserMovie from './UserMovie';
import Loading from '@/components/Loading/index.vue';
// import UserForward from "./UserForward"
import { mapGetters } from 'vuex';
import { setSessionItem } from '@/utils/longStorage';
import { Tab, Tabs, Popover, Popup, Toast, Sticky, Field } from 'vant';
import { getMedal } from '@/utils/getConfig';
import { queryUserInfo } from '@/api/user';
import { queryRewardLog, querySessionId, publisherTop } from '@/api/mine';
import { careOrcancle } from '@/api/user';
import { reward, queryCollection } from '@/api/video';
export default {
  name: 'UserHomePage',
  components: {
    Vlogo,
    ImgDecypt,
    UserVideo,
    UserPost,
    UserMovie,
    // UserForward,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Popup.name]: Popup,
    [Popover.name]: Popover,
    [Sticky.name]: Sticky,
    [Field.name]: Field,
    Loading,
  },
  watch: {
    $route(to, from) {
      // console.log(!to.query.fromPath)
      if (to.path == from.path && from.query.uid != to.query.uid) {
        this.$router.go(0);
      }
    },
  },
  data() {
    return {
      isLoading: true,
      myInfo: this.$store.getters.userInfo,
      userInfo: {},
      activeName: 0, // 选中tab
      rewardList: [],
      rewardTotal: 0,
      showPopover: false,
      showPop: false,
      showNav: false,
      isReward: false, // 打赏弹窗
      balance: 0, // 余额
      amountList: [2, 10, 20, 50, 100], // 默认金额列表
      activeAmount: '', // 选中金额
      inpAmount: '', // 输入金额
      iconList: [], // 用户标识
      backgroundImg: '', //背景图
      movieList: [],
      actions: [
        {
          text: '举报',
          value: 'report',
        },
        {
          text: '切换背景',
          value: 'changeBgImg',
        },
      ],
      showPressTip: false,
      // testshow: true,
    };
  },
  computed: {
    ...mapGetters({
      setTopData: 'setTopData',
    }),
    userIcons() {
      return getMedal(this.userInfo?.awardsExpire || []) || [];
    },
  },
  // beforeDestroy() {
  //   this.setTopClose();
  // },
  created() {
    this.showPress();
    this.queryMovieList();
    this.amountList = this.$store.getters.appConfig.rewardMoney ? this.$store.getters.appConfig.rewardMoney : [2, 10, 20, 50, 100];
    let uInfo = this.$store.getters.userInfo;
    let uid = this.$route.query.uid;
    if (!uid || (uid && uid == uInfo.uid)) {
      this.actions.shift();
    } else {
      this.actions.pop();
    }
    this.queryUserInfo();
    this.queryRewardLog();
  },
  activated() {
    this.queryUserInfo();
    if (this.setTopData.id) {
      this.$store.commit('user/UPDATE_SETTOPDATA_SHOW', false);
      if (this.setTopData.type == 'SP') {
        this.$refs.video?.refreshList();
      } else if (this.setTopData.type == 'COVER') {
        this.$refs.post?.refreshList();
      } else {
        this.$refs.movie?.refreshList();
      }
    }
  },

  methods: {
    showPress() {
      this.showPressTip = localStorage.getItem('isShowPressTip') == 0 ? false : true;
    },
    // 关闭长按提示
    closePressTip() {
      localStorage.setItem('isShowPressTip', '0');
      this.showPressTip = false;
    },
    goRouter() {
      if (this.setTopData.status == 0) {
        Toast('当前作品未审核');
        return;
      }
      if (this.setTopData.type == 'COVER') {
        Toast('图文暂不支持推广');
        return;
      }
      if (this.setTopData.type == 'MOVIE') {
        Toast('影视暂不支持推广');
        return;
      }
      if (this.setTopData.promoteStatus == 1) {
        Toast('当前视频正在推广中');
        return;
      }
      if (this.setTopData.publisherPopStatus) {
        Toast('当前视频正在审核中');
        return;
      }
      if (!this.checkVip()) {
        // Toast('抱歉，只有会员才可以置顶');
        this.$bus.$emit('promptPopupEvent', '抱歉，只有会员才能推广');
        return;
      }
      this.$store.commit('user/UPDATE_SETTOPDATA_SHOW', false);
      this.$router.push({
        path: '/promotiionSettings',
      });
    },
    // 查询列表
    async queryMovieList() {
      let req = {
        pageNumber: 1,
        pageSize: 10,
        type: 'new', // hot-热度值排序 watch-最多播放 like-最多点赞(收藏) new-最新
        newsType: 'MOVIE', // SP-视频 COVER-图集帖子 MOVIE-影视  FORWARD-转发帖子
        uid: this.$route.query.uid != this.$store.getters.userInfo.uid ? this.$route.query.uid : undefined,
      };
      let res = await this.$Api(queryCollection, req);
      if (res.code === 200) {
        this.movieList = res.data.list || [];
      } else {
        this.error = true;
      }
    },
    // 复制ID
    copyUrl(id) {
      this.$copyText(JSON.stringify(id)).then(
        (e) => {
          Toast('复制成功');
        },
        (e) => {
          Toast('复制失败');
        },
      );
    },
    // 监听滚动
    handleScroll() {
      let scrollTop = this.$refs.userHomePage.scrollTop;
      // console.log(scrollTop)
      if (scrollTop > 275) {
        this.showNav = true;
      } else {
        this.showNav = false;
      }
    },
    // 举报
    selectPopover(action) {
      this.showPopover = false;
      this.showPop = false;
      let uid = this.$route.query.uid;
      if (action.value == 'report') {
        this.$router.push({
          path: '/report',
          query: {
            uid: uid,
          },
        });
      } else if (action.value == 'changeBgImg') {
        // if (!this.checkVip()) {
        //   Toast('抱歉，只有会员才可以修改背景')
        //   return;
        // }
        this.$router.push('/changeBackground');
      }
    },
    // 跳转关注
    jumpFocusOn() {
      if (this.$route.query.uid != this.myInfo.uid && this.$route.query.uid != undefined) return;
      this.$router.push({
        path: '/focusOn',
        query: {
          uid: this.$route.query.uid,
        },
      });
    },
    // 跳转粉丝
    jumpFans() {
      if (this.$route.query.uid != this.myInfo.uid && this.$route.query.uid != undefined) return;
      this.$router.push({
        path: '/fans',
        query: {
          uid: this.$route.query.uid,
        },
      });
    },
    // 修改关注状态
    followedAllSuccess(uid) {
      if (this.userInfo.uid == uid) {
        this.userInfo.isFollow = true;
      }
    },
    // 查询用户信息
    async queryUserInfo() {
      try {
        let req = this.$route.query.uid
          ? {
              uid: this.$route.query.uid != this.$store.getters.userInfo.uid ? this.$route.query.uid : undefined,
            }
          : undefined;
        let res = await this.$Api(queryUserInfo, req);
        if (res.code === 200) {
          this.userInfo = res.data;
          this.backgroundImg = this.userInfo.background ? this.userInfo.background[0] : '';
        }
      } finally {
        this.isLoading = false;
      }
    },
    // 去重
    // unique(arr) {
    //   const res = new Map();
    //   return arr.filter((arr) => !res.has(arr.id) && res.set(arr.id, 1));
    // },
    // 查询打赏
    async queryRewardLog() {
      let req = {
        pageNumber: 1,
        pageSize: 4,
        uid: this.$route.query.uid ? this.$route.query.uid : undefined,
      };
      let res = await this.$Api(queryRewardLog, req);
      if (res.code === 200) {
        let arr = res.data.list || [];
        // this.unique(arr)
        this.rewardList = arr.reverse();
        this.rewardTotal = res.data.total;
      }
    },
    // 关注
    async follow() {
      let req = {
        followUID: this.userInfo.uid,
        isFollow: !this.userInfo.isFollow,
      };
      if (!this.userInfo.uid) {
        Toast('当前用户信息错误');
        return;
      }
      this.$store.commit('app/SET_LOADING', true);
      try {
        let res = await this.$Api(careOrcancle, req);
        this.$store.commit('app/SET_LOADING', false);
        if (res.code === 200) {
          Toast(req.isFollow ? '关注成功' : '取消成功');
          this.userInfo.isFollow = req.isFollow;
          req.isFollow ? this.userInfo.fans++ : this.userInfo.fans--;
          let followInfo = { uid: this.userInfo.uid, status: req.isFollow };
          setSessionItem('followInfo', JSON.stringify(followInfo));
          if (this.$refs.post) {
            this.$refs.post.changeFollow(req);
          }
          if (this.$refs.forward) {
            this.$refs.forward.changeFollow(req);
          }
          // this.$refs.post.changeFollow(req);

          return;
        }
        Toast(res.tip || `${req.isFollow ? '关注失败' : '取消关注失败'}`);
      } catch (error) {
        Toast('操作失败');
        this.$store.commit('app/SET_LOADING', false);
      }
    },
    // 私信
    async privateLetter() {
      Toast('敬请期待');
      return;
      // console.log(this.checkVip())
      // if (!this.checkVip()) {
      //   Toast('抱歉，只有会员才可以私信');
      //   return;
      // }
      // let req = {
      //   takeUid: this.userInfo.uid,
      // };
      // this.$store.commit('app/SET_LOADING', true);
      // try {
      //   let res = await this.$Api(querySessionId, req);
      //   if (res && res.code == 200) {
      //     // console.log(res)
      //     this.$store.commit('app/SET_LOADING', false);
      //     let encode = window.encodeURI(
      //       JSON.stringify({
      //         noReadNum: 0,
      //         userName: this.userInfo.name,
      //         userAvatar: this.userInfo.portrait,
      //         sessionId: res.data,
      //         takeUid: this.userInfo.uid,
      //         sendUid: this.$store.getters.userInfo.uid,
      //       }),
      //     );
      //     let data = {
      //       path: '/msgDetails',
      //       query: {
      //         data: window.btoa(encode),
      //       },
      //     };
      //     this.$router.push(data);
      //   }
      // } catch (error) {
      //   Toast('操作失败');
      //   this.$store.commit('app/SET_LOADING', false);
      // }
    },
    async showReward() {
      await this.$store.dispatch('user/getWallet');
      let walletInfo = this.$store.getters.walletInfo;
      this.balance = (walletInfo.amount || 0 + walletInfo.income || 0).toFixed(2);
      this.isReward = true;
    },
    // 获取焦点清除选中金额
    inputAmount() {
      this.activeAmount = '';
    },
    // 选择金额
    selectAmout(amout) {
      this.activeAmount = amout;
      this.inpAmount = '';
    },
    // 设置金额
    setAmount() {
      if (!this.inpAmount && !this.activeAmount) {
        Toast('请输入或选择打赏金额');
        return;
      }
      if (Number(this.inpAmount) && Number(this.inpAmount) > Number(this.balance)) {
        Toast('余额不足，请前往充值');
        return;
      }
      if (this.activeAmount && this.activeAmount > Number(this.balance)) {
        Toast('余额不足，请前往充值');
        return;
      }
      let amount = Number(this.inpAmount) || this.activeAmount;
      this.reward(amount);
    },
    async reward(amount) {
      let req = {
        uid: this.userInfo.uid,
        coins: String(amount),
      };
      this.$store.commit('app/SET_LOADING', true);
      try {
        let res = await this.$Api(reward, req);
        this.$store.commit('app/SET_LOADING', false);
        if (res.code === 200) {
          Toast('打赏成功');
          this.isReward = false;
          this.queryUserInfo();
          this.queryRewardLog();
          this.$store.dispatch('user/getWallet');
          return;
        }
        Toast(res.tip || '打赏失败');
      } catch (error) {
        Toast('打赏失败');
        this.$store.commit('app/SET_LOADING', false);
      }
    },

    // 关闭弹窗
    close() {
      this.activeAmount = '';
      this.inpAmount = '';
    },

    setTopClose() {
      let data = {
        id: '',
        action: 2,
        popShow: false,
        type: 'SP',
        status: 0,
        promoteStatus: 2,
        publisherPopStatus: false,
        promoteInfo: {
          cover: '',
          title: '',
          user: {},
        },
      };
      this.$store.commit('user/UPDATE_SETTOPDATA', data);
    },
    selectSetTop(action) {
      if (!this.checkVip()) {
        // Toast('抱歉，只有会员才可以置顶');
        this.$bus.$emit('promptPopupEvent', '抱歉，只有会员才可以置顶');
        return;
      }
      if (this.setTopData.action == action) return;
      this.publisherTop(action);
    },
    async unset(action) {
      if (this.setTopData.promoteStatus == action) return;
      try {
        this.$store.commit('app/SET_LOADING', true);
        let data = {
          videoId: this.setTopData.id,
          actionPop: 2,
          type: this.setTopData.type,
          action: this.setTopData.action,
        };
        let res = await this.$Api(publisherTop, data);
        if (res.code == 200) {
          Toast('操作成功');
          if (this.setTopData.type == 'SP') {
            this.$refs.video?.refreshList();
          } else if (this.setTopData.type == 'COVER') {
            this.$refs.post?.refreshList();
          } else {
            this.$refs.movie?.refreshList();
          }
          this.setTopClose();
          return;
        }
        Toast(res.tip || '操作失败');
      } finally {
        this.$store.commit('app/SET_LOADING', false);
      }
    },
    // 校验是否会员、会员时间是否到期
    checkVip() {
      let uInfo = this.$store.getters.userInfo;
      let todayTime = new Date().getTime();
      let effectiveTime = new Date(uInfo.vipExpireDate).getTime();
      if (uInfo && uInfo.isVip && todayTime < effectiveTime && uInfo.vipLevel > 0) {
        return true;
      }
      return false;
    },
    // 确认置顶
    async publisherTop(action) {
      let req = {
        action: action,
        videoId: this.setTopData.id,
        type: this.setTopData.type,
      };
      // console.log(req)
      try {
        this.$store.commit('app/SET_LOADING', true);
        let ret = await this.$Api(publisherTop, req);
        this.$store.commit('app/SET_LOADING', false);
        // console.log(ret)
        if (ret && ret.code == 200) {
          Toast('操作成功');
          this.setTopData.action = action;
          if (this.setTopData.type == 'SP') {
            this.$refs.video?.refreshList();
          } else if (this.setTopData.type == 'COVER') {
            this.$refs.post?.refreshList();
          } else {
            this.$refs.movie?.refreshList();
          }
          this.setTopClose();
          return;
        }
        Toast(ret.tip || '操作失败');
      } catch (error) {
        this.$store.commit('app/SET_LOADING', false);
        Toast('操作失败');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.userHomePage {
  height: 100%;
  background-color: rgb(255, 255, 255);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .scrollList {
    height: 100%;
  }
  .vipName {
    color: #ff678f;
  }

  .navBar {
    background: rgb(255, 255, 255);
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 99;
    height: 60px;
    padding: 0 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(217, 217, 217);
    .centerBox {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
    }

    .navAvatar {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }

    .leftBtn {
      width: 24px;
      height: 24px;
    }

    .title {
      font-size: 20px;
    }

    .rightBtn {
      width: 24px;
      height: 24px;
      .horizontalMore {
        height: 100%;
        width: 100%;
      }
    }
  }

  .header {
    width: 100%;
    height: 164px;
    position: relative;
    .backBtn {
      position: absolute;
      top: 16px;
      left: 16px;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      > svg {
        height: 36px;
        width: 36px;
      }
    }
    .moreBox {
      position: absolute;
      top: 16px;
      right: 16px;
    }

    .moreBtn {
      border-radius: 50%;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;

      .more {
        width: 36px;
        height: 36px;
      }
    }
  }

  .isCertifica {
    border-radius: 50%;
    border: 2px solid #d82d0b;
  }

  .optBox {
    height: 36px;
    padding: 0 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 8px;
    position: relative;
    .avatarImgBox {
      width: 76px;
      height: 76px;
      position: absolute;
      left: 12px;
      top: -40px;
      .avatarImg {
        background: #00000000;
      }

      .certification {
        width: 20.1px;
        height: 20.2px;
        border-radius: 50%;
        position: absolute;
        right: 0;
        bottom: 0;
        overflow: hidden;
        transform-style: preserve-3d;
      }
      .certificaBorder {
        position: absolute;
        top: -18px;
        width: 100%;
        font-size: 50px;
        z-index: 1;
      }
    }
    .smsButtom {
      width: 56px;
      height: 25px;
      line-height: 25px;
      font-size: 14px;
      border-radius: 16px;
      margin-right: 12px;
      border: 1px solid #ffffff80;
      text-align: center;
      border: 1px solid rgb(255, 103, 143);
      color: rgb(255, 103, 143);
    }

    .follow {
      width: 56px;
      height: 25px;
      border-radius: 16px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgb(255, 103, 143);
      box-sizing: border-box;
      background-color: rgb(249, 230, 236);
    }

    .followed {
      width: 56px;
      height: 25px;
      border-radius: 16px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgb(201, 201, 201);
      border: 1px solid rgb(201, 201, 201);
      box-sizing: border-box;
    }
  }
  .userBox {
    margin: 5px 0 0 5px;
    .userInfo {
      padding: 0 0 0 10px;
      box-sizing: border-box;
      display: flex;
      align-items: center;

      .userName {
        font-size: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .userSexIcon {
        width: 20px;
        height: 20px;
      }
      .userIcon {
        width: 30px;
        height: 30px;
      }
      .userIcon:first-of-type {
        margin-left: 3px;
      }
    }

    .userId {
      padding: 0 12px;
      box-sizing: border-box;
      font-size: 11px;
      color: rgb(102, 102, 102);
    }
  }
  .introduce {
    padding: 0 16px;
    margin-top: 6px;
    box-sizing: border-box;
    font-size: 14px;
    white-space: pre-wrap;
    color: rgb(102, 102, 102);
  }

  .userData {
    padding: 0 42px;
    box-sizing: border-box;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    height: 60px;
    span {
      color: rgb(153, 153, 153);
      font-size: 14px;
      font-weight: 400;
    }
  }
  .rewardBox {
    background: rgb(245, 245, 245);
    height: 68px;
    padding-top: 8px;
    .reward {
      height: 52px;
      border-radius: 60px;
      margin: 4px 12px 9px;
      background: rgb(255, 255, 255);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;
      .avatars {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 120px;

        .avatarItem {
          width: 38px;
          height: 38px;
          display: inline-block;
          position: relative;
          margin-right: -10px;
          border-radius: 50%;

          /deep/ .vanImage {
            background: #00000000 !important;
          }
        }
      }

      .rewardNum {
        font-size: 14px;
        color: rgb(102, 102, 102);
      }

      .noReward {
        margin-left: 30px;
      }

      .rewardBtn {
        width: 56px;
        height: 25px;
        line-height: 25px;
        border-radius: 25px;
        font-size: 14px;
        text-align: center;
        background-color: rgb(249, 230, 236);
        color: rgb(255, 103, 143);
      }
    }
  }

  .vant_tabs {
    /deep/ .van-tabs__wrap {
      height: 40px;
      margin: 0 auto;
      box-sizing: border-box;
      border-bottom: 1px solid #e0e0e0;
    }

    /deep/ .van-tabs__content {
      height: 100%;
    }

    /deep/ .van-tab__pane {
      height: 100%;
    }

    /deep/ .van-tab__text {
      font-size: 16px;
    }

    /deep/ .van-tabs__line {
      width: 20px;
      height: 3px;
      border-radius: 4px;
      background: rgb(255, 103, 143);
    }

    /deep/ .van-tab__pane-wrapper {
      height: 100%;
    }

    /deep/ .van-sticky {
      top: 44px;
    }

    .vList {
      padding: 8px 16px 0;
      box-sizing: border-box;
    }
  }

  .listPadding {
    padding: 12px 16px 0;
    box-sizing: border-box;
  }

  .setAmoutBox {
    background: #00000000;

    .popupsBox {
      width: 327px;
      // height: 264px;
      padding: 14px 24px 18px;
      border-radius: 10px;
      box-sizing: border-box;
      background: url('./../../../assets/png/reward_bg.png') no-repeat;
      background-size: 100% 100%;
      color: #171717;

      .popTitle {
        font-size: 16px;
        text-align: center;
        margin-bottom: 19px;
      }

      .amountList {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-row-gap: 8px;
        grid-column-gap: 8px;

        .amountItem {
          width: 81px;
          height: 37px;
          font-size: 18px;
          border-radius: 4px;
          border: 1px solid rgb(255, 103, 143);
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgb(255, 244, 247);
          box-sizing: border-box;

          /deep/ .van-field {
            width: 100%;
            height: 100%;
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;
          }

          /deep/ .van-field__control {
            text-align: center;
          }
        }

        .activeAmout {
          color: #fff;
          background: rgb(255, 151, 178);
        }

        .goldIcon {
          margin-right: 3px;
        }
      }

      .balance {
        font-size: 14px;
        margin-top: 16px;
        color: rgb(102, 102, 102);
      }

      .popBtn {
        width: 244px;
        height: 38px;
        line-height: 38px;
        border-radius: 38px;
        margin: 12px auto 0;
        color: rgb(255, 103, 143);
        text-align: center;
        border: 1px solid rgb(255, 103, 143);
      }
    }

    .closeBtn {
      margin-top: 24.5px;
      display: flex;
      align-items: center;
      justify-content: center;

      .closeIcon {
        width: 33.2px;
        height: 33.2px;
      }
    }
  }
  .setTopPop {
    // height: 249px;
    padding: 8px 0px 15px;
    border-radius: 12px 12px 0 0;
    background: #f8f8f8;
    .line {
      height: 4px;
      width: 36px;
      border-radius: 4px;
      background-color: rgb(222, 222, 222);
      margin: 0 auto 22px;
    }
    .contentBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 15px;
      box-sizing: border-box;
      .contentBox_item {
        background: #fff;
        border-radius: 12px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 15px;
        margin: 10px auto;
        box-sizing: border-box;
      }
      .leftBox {
        color: #666666;
        font-size: 14px;
        display: flex;
        align-items: center;
        .setTop {
          font-size: 22px;
          margin-right: 10px;
        }
      }
      .rightBox {
        width: 116px;
        height: 32px;
        border-radius: 6px;
        font-size: 14px;
        padding: 0 6px;
        box-sizing: border-box;
        background: #f6f6f6;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #000;
        line-height: 32px;
        .setTopBtn {
          width: 61px;
          height: 32px;
          text-align: center;
          color: #6f7074;
        }
        .activeBtn {
          width: 61px;
          height: 32px;
          border-radius: 5px;
          text-align: center;
          background: #fff;
        }
      }
    }
  }

  .pressTip {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #15151575;
    z-index: 99;
    .pressBox {
      position: fixed;
      bottom: 50px;
      left: 20px;
      font-size: 17px;
      color: #fff;
      .pressImg {
        width: 57px;
        height: 57px;
        background: url('../../../assets/png/press.png');
        background-size: 100% 100%;
        margin: 0 auto 12px;
      }
    }
  }
}
</style>
